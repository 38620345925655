.neon {
  text-align: center;
  width: 65%;
  height: 250px;
  margin: auto;
  user-select: none;
}

.neon b {
  font: 500 10vh 'K2D';
  color: #fee;
  text-shadow: 0 -20px 100px, 0 0 2px, 0 0 1em #09ff00, 0 0 0.4em #09ff00,
    0 0 0.1em #09ff00, 0 5px 4px #000;
}

.neon b span {
  animation: blink linear infinite 2s;
}

.neon b span:nth-of-type(2) {
  animation: blink linear infinite 3s;
}

@keyframes blink {
  78% {
    color: inherit;
    text-shadow: inherit;
  }
  79% {
    color: #333;
  }
  80% {
    text-shadow: none;
  }
  81% {
    color: inherit;
    text-shadow: inherit;
  }
  82% {
    color: #333;
    text-shadow: none;
  }
  83% {
    color: inherit;
    text-shadow: inherit;
  }
  92% {
    color: #333;
    text-shadow: none;
  }
  92.5% {
    color: inherit;
    text-shadow: inherit;
  }
}
