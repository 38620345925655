@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=K2D:wght@600');

* {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
*::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

body {
  background-color: black;
  font-family: 'K2D';
}

nav p:hover {
  color: #afffaf;
  filter: drop-shadow(1px 1px 15px #00f700);
  cursor: pointer;
}

.hover-green:hover {
  color: #afffaf;
  filter: drop-shadow(1px 1px 5px #00f700);
}
